<template>
	<div>
		<el-page-header @back="$router.back()" content="订单详情" class="margin-bottom margin-top-xs"></el-page-header>
		<div>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="120px">
					<el-form-item label="活动名称" prop="title" :rules="rules.required">
						<el-input v-model="form.title" placeholder="请输入活动名称"></el-input>
					</el-form-item>
					<el-form-item label="活动结束时间" prop="daterange" :rules="rules.required">
						<div class="form-item-box">
							<el-date-picker v-model="form.daterange" type="daterange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
								value-format="yyyy-MM-dd">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="原价" prop="price" :rules="[rules.required,rules.notZero]">
						<div class="form-item-box">
							<el-input-number v-model="form.price" :min="0" :max="99999"></el-input-number>
						</div>
					</el-form-item>
					<el-form-item label="拼团价" prop="promotion_price" :rules="[rules.required,rules.notZero]">
						<div class="form-item-box">
							<el-input-number v-model="form.promotion_price" :min="0" :max="99999"></el-input-number>
						</div>
					</el-form-item>
					<el-form-item label="库存" prop="stock" :rules="rules.required">
						<div class="form-item-box">
							<el-input-number v-model="form.stock" :min="0" :max="99999"></el-input-number>
						</div>
					</el-form-item>
					<el-form-item label="拼团有效期" prop="expire_days" :rules="rules.checkExpireTime">
						<el-input placeholder="请输入内容" v-model="form.expire_days" style="width: 200px;">
							<template slot="append">天</template>
						</el-input>
						<el-input placeholder="请输入内容" v-model="form.expire_hours" style="width: 200px;">
							<template slot="append">小时</template>
						</el-input>
						<el-input placeholder="请输入内容" v-model="form.expire_minutes" style="width: 200px;">
							<template slot="append">分钟</template>
						</el-input>
					</el-form-item>
					<el-form-item label="参团人数" prop="num" :rules="rules.required">
						<div class="form-item-box">
							<el-input-number v-model="form.num" :min="0" :max="99999"></el-input-number>
						</div>
					</el-form-item>
					<el-form-item label="核销码设置" prop="check_code" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.check_code" placeholder="请输入核销密码"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="客服电话" prop="customer_service_phone" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.customer_service_phone" placeholder="请输入客服电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="客服二维码" prop="customer_service_qrcode" :rules="rules.required">
						<div class="flex justify-center align-center img-btn"
							@click="upload('.avatar-uploader-editoer input')">
							<el-image v-if="form.customer_service_qrcode" style="width: 100%; height: 100%"
								:src="form.customer_service_qrcode" fit="cover"></el-image>
							<i v-else class="el-icon-plus"></i>
						</div>
						<!-- 图片上传组件辅助-->
						<el-upload class="avatar-uploader-editoer" style="display: none;" :action="uploadAction"
							:show-file-list="false" :on-success="uploadSuccess" :on-error="uploadError"
							:before-upload="beforeUpload">
						</el-upload>
						<div class="text-sm text-grey">尺寸：750*750像素，小于2M，支持jpg、png格式</div>
					</el-form-item>
					<el-form-item label="添加活动轮播图" prop="images" :rules="rules.required">
						<div class="flex flex-wrap">
							<div class="flex justify-center align-center img-btn margin-right"
								v-for="(item,index) in form.images" :key="item">
								<el-image style="width: 100px; height: 100px" :src="item" fit="cover"></el-image>
								<span class="el-upload-list__item-actions">
									<span class="preview" @click="handlePictureCardPreview(item)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span class="delete" @click="handleRemove(index)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
							<div class="flex justify-center align-center img-btn margin-right"
								@click="upload('.avatar-uploader-editoer-banner input')">
								<i class="el-icon-plus"></i>
							</div>
						</div>
						<!-- 图片上传组件辅助-->
						<el-upload class="avatar-uploader-editoer-banner" style="display: none;" :action="uploadAction"
							:show-file-list="false" :on-success="bannerUploadSuccess" :on-error="uploadError"
							:before-upload="beforeUpload">
						</el-upload>
						<div class="text-sm text-grey">尺寸：750*750像素，小于2M，支持jpg、png格式</div>
					</el-form-item>
					<el-form-item label="活动规则">
						<el-input type="textarea" rows="4" v-model="form.rules" placeholder="请输入活动规则"></el-input>
					</el-form-item>
					<el-form-item label="活动介绍" prop="content" :rules="rules.required">
						<quill-editor ref="myQuillEditor" v-model="form.content" :options="editorOption">
						</quill-editor>
						<!-- 图片上传组件辅助-->
						<el-upload class="avatar-uploader-editoer-editor" :action="uploadAction" :show-file-list="false"
							:on-success="editorUploadSuccess" :on-error="uploadError" :before-upload="beforeUpload">
						</el-upload>
					</el-form-item>
					<el-form-item label="限购规则">
						<el-radio-group v-model="form.is_buylimit">
							<el-radio :label="0">不限购</el-radio>
							<el-radio :label="1">限购</el-radio>
						</el-radio-group>
						<el-input-number v-if="form.is_buylimit == 1" class="margin-left" v-model="form.limit_num"
							:min="1" :max="99999"></el-input-number>
					</el-form-item>
					<el-form-item label="模拟成团">
						<el-switch v-model="form.is_auto_close" :active-value="1" :inactive-value="0"></el-switch>
						<div class="text-sm text-grey" style="line-height: 20px;">
							开启模拟成团后，满足条件的团，系统将会模拟“匿名买家”凑满该团，仅需对真实拼团买家发货。建议合理开启，以提高成团率。</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	import axios from 'axios'
	// 工具栏配置
	const toolbarOptions = [
		// ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
		// ["blockquote", "code-block"], // 引用  代码块
		// [{ header: 1 }, { header: 2 }], // 1、2 级标题
		// [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
		// [{ script: "sub" }, { script: "super" }], // 上标/下标
		// [{ indent: "-1" }, { indent: "+1" }], // 缩进
		// [{'direction': 'rtl'}],                         // 文本方向
		// [{ size: ["small", false, "large", "huge"] }], // 字体大小
		// [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
		// [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
		// [{ font: [] }], // 字体种类
		// [{ align: [] }], // 对齐方式
		// ["clean"], // 清除文本格式
		// ["link", "image", "video"] // 链接、图片、视频
		["image"]
	];
	export default {
		data() {
			var checkExpire = (rule, value, callback) => {
				var val = Number(this.form.expire_days) * 86400 + Number(this.form.expire_hours) * 3600 + Number(this
					.form
					.expire_minutes) * 60
				if (isNaN(val)) {
					callback(new Error('只能输入数字'));
				} else if (val < 3600) {
					callback(new Error('至少需要一个小时'));
				} else {
					callback();
				}
			};
			return {
				dialogVisible: false, // 预览图片框
				dialogImageUrl: '', // 预览图片地址
				uploadAction: axios.defaults.baseURL + this.$api.common.UPLOAD_URL,
				rules: {
					...rules,
					checkExpireTime: {
						validator: checkExpire,
						trigger: 'blur'
					}
				},
				form: {
					id: '',
					title: '', // 活动标题
					daterange: [], // 开始结束时间
					// start_time: '',
					// end_time: '',
					is_need_check: 1, // 是否需要核销
					check_code: '', // 核销码
					price: '', // 原价
					promotion_price: '', // 活动价
					stock: '', // 库存
					num: 2, // 成团人数
					customer_service_phone: '', // 客服电话
					customer_service_qrcode: '', // 客服二维码
					images: [], // 活动图片
					rules: '', // 活动规则
					content: '', // 活动详情
					is_buylimit: 0, // 是否限购
					limit_num: '', // 拼团限购数量
					is_auto_close: 1, // 是否模拟成团
					expire_days: 1,
					expire_hours: 0,
					expire_minutes: 0,
					// expire_time: '', // 拼团有效期（单位：秒）
				},
				editorOption: {
					placeholder: "请输入活动介绍",
					modules: {
						toolbar: {
							container: toolbarOptions,
							// container: "#toolbar",
							handlers: {
								image: function(value) {
									if (value) {
										// 触发input框选择图片文件
										document.querySelector(".avatar-uploader-editoer-editor input").click();
									} else {
										this.quill.format("image", false);
									}
								}
							}
						}
					}
				},
			};
		},
		computed: {
			submitForm: {
				get() {
					let sf = this.$util.deepClone(this.form)
					// daterange: [], // 开始结束时间
					sf.start_time = sf.daterange.length ? sf.daterange[0] : '';
					sf.end_time = sf.daterange.length ? sf.daterange[1] : '';
					sf.expire_time = Number(sf.expire_days) * 86400 + Number(sf.expire_hours) * 3600 + Number(sf
						.expire_minutes) * 60;
					sf.expire_time = isNaN(sf.expire_time) ? '' : sf.expire_time;
					delete sf.daterange;
					delete sf.expire_days;
					delete sf.expire_hours;
					delete sf.expire_minutes;
					return sf;
				},
				set(val) {
					this.form = val;
					this.form.daterange = [val.start_time, val.end_time];
					val.expire_time = 86470;
					let rest = val.expire_time;
					this.form.expire_days = parseInt(val.expire_time / 86400);
					rest = rest - this.form.expire_days * 86400;
					this.form.expire_hours = parseInt(rest / 3600);
					rest = rest - this.form.expire_hours * 3600;
					this.form.expire_minutes = rest / 60;
				}
			}
		},
		mounted() {
			// 获取当前编辑的会员信息
			if (this.$route.params.id) {
				this.$api.marketing.getGroupById({
					id: this.$route.params.id
				}).then(res => {
					console.log(res.data)
					this.submitForm = res.data;
				});
			}
		},
		methods: {
			handleRemove(index) {
				this.$confirm('确定要删除吗', '提示', {
					type: 'warning'
				}).then(() => {
					this.form.images.splice(index, 1)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 预览图片
			handlePictureCardPreview(src) {
				this.dialogImageUrl = src;
				this.dialogVisible = true;
			},
			// ==========上传相关开始==========
			upload(selecter) {
				document.querySelector(selecter).click();
			},
			editorUploadSuccess(res) {
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.quill;
				// 如果上传成功
				if (res.code == 200) {
					// 获取光标所在位置
					let length = quill.getSelection().index;
					// 插入图片  res.url为服务器返回的图片地址
					quill.insertEmbed(length, "image", res.data);
					// 调整光标到最后
					quill.setSelection(length + 1);
				} else {
					this.$message.error("图片插入失败");
				}
				// loading动画消失
				this.loading = false;
			},
			beforeUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('只能上传图片文件!');
				}
				if (!isLt2M) {
					this.$message.error('图片大小不能超过 2MB!');
				}
				// 校验成功开始上传，打开loading
				this.loading = isJPG && isLt2M;
				return isJPG && isLt2M;
			},
			bannerUploadSuccess(res) {
				// 如果上传成功
				if (res.code == 200) {
					this.form.images.push(res.data)
				} else {
					this.$message.error("图片上传失败");
				}
				// loading动画消失
				this.loading = false;
			},
			uploadSuccess(res) {
				// res为图片服务器返回的数据
				// 如果上传成功
				if (res.code == 200) {
					this.form.customer_service_qrcode = res.data;
				} else {
					this.$message.error("图片上传失败");
				}
				// loading动画消失
				this.loading = false;
			},
			uploadError() {
				// loading动画消失
				this.loading = false;
				this.$message.error("图片上传失败");
			},
			// ==========上传相关结束==========
			onSubmit() {
				console.log(this.submitForm)
				this.$refs.form.validate(valid => {
					if (valid) {
						// return;
						const loading = this.$loading();
						this.$api.marketing.saveGroup(this.submitForm).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}

	.img-btn {
		width: 100px;
		height: 100px;
		border: 1px dashed var(--grey);
		cursor: pointer;
		border-radius: 6px;
		position: relative;

		.el-upload-list__item-actions {
			cursor: default;
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(0, 0, 0, 0.3);
			opacity: 0;

			.preview,
			.delete {
				cursor: pointer;
				font-size: 20px;
				color: #FFF;
				margin: 0 5px;
			}
		}

		&:hover {
			border: 1px dashed var(--blue);

			.el-upload-list__item-actions {
				opacity: 1;
			}
		}

		.el-icon-plus {
			font-size: 30px;
			color: var(--grey);
		}
	}

	.quill-editor>>>.ql-snow .ql-editor img {
		width: 100%;
	}
</style>
